import React from 'react'

export default function Platformcommision() {
  return (
    <div>
       <div className="leftContainer">
      <div className="main-area" style={{ paddingTop: "35px" }}>
        <section className="games-section p-3 mt-3 py-4">
          <div className="m-3">
            <h1>Platform Commission</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Platform Commission
                </li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-12">
                <p>
                  Platfrom commission is taken by each participant equally in
                  every contest or battle. Commission of any battle/contest is
                  shown to participant before joining the contest/battle.
                </p>
                <h4>Below 500 cash games,</h4>
                <ul>
                  <li>
                    We charge 5-30% commission depending on the entry amount.
                  </li>
                </ul>
                <h4>Above 500 cash games,</h4>
                <ul>
                  <li>We charge flat 5% commission on each battle.</li>
                </ul>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    </div>
  )
}
