import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/landing.css";
import Downloadbutton from "../Components/Downloadbutton";
import Swal from "sweetalert2";
import Footer from "../uiComponents/Footer";
import close from "../../assets/images/global-circularCrossIcon.png";
import logo from '../../assets/images/logo.png'
import closeicon from '../../assets/images/global-purple-battleIcon.png'
import youtube from '../../assets/images/global-ytPlayIcon.png'
export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [commission, setcommission] = useState();
  const [whatappNumber, setWhatappNumber] = useState();
  const [refer, setrefer] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [blockMessage, setBlockMessage] = useState("");
  const [userBlock, setUserBlock] = useState(0);
  const [msg, setmsg] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setcommission(data.commission);
    setWhatappNumber(data.CompanyMobile);
    setrefer(data.refer);
    setmsg(data.msg);
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        const passworduser = res.data.Password;
      })

      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  useEffect(() => {
    
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
    }
    role();
    fetchData();
  }, [userBlock]);

  const [activeIndex, setActiveIndex] = useState(null);

  const onItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const webname = "Winbro"
  return (
    <>
      <div></div>

      {userBlock > 0 ? <div>{blockMessage}</div> : <div>Login Panel</div>}

      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "35px" }}>
          <div className="header_top_message">
            <span>Commission:5% ◉ Referral: 3% For All Games</span>
          </div>
          <div className="header_top_message">
            <span>{msg}</span>
          </div>
          <div className="collapseCard-container">
            <div className="collapseCard">
              <a href="#!" style={{ textDecoration: "none" }}>
                <div
                  className="collapseCard-body"
                  style={{
                    height: 64,
                    opacity: 1,
                    transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                  }}
                >
                  <div className="collapseCard-text">How to win money?</div>
                  <picture className="collapseCard-closeIcon">
                    <img
                      className="position-relative"
                      src={close}
                      alt="Close"
                      width="14px"
                      height="14px"
                    />
                  </picture>
                </div>
              </a>
              <div
                className="collapseCard-header"
                style={{ left: 22, transition: "left 0.3s ease 0s" }}
              >
                <picture>
                  <img
                    height="10px"
                    width="14px"
                    src={youtube}
                    alt=""
                  />
                </picture>
                <div className="collapseCard-title ml-1 mt-1">Video Help</div>
              </div>
            </div>
          </div>

          <section className="games-section padding_new">
            <div class="d-flex align-items-center games-section-title">
              Our Games
            </div>
            <div class="games-section-headline mt-2 mb-1">
              <img src={closeicon} alt="" /> is for
              Battles and
              <img
                className="ml-1"
                src="/images/global-blue-tournamentIcon.png"
                alt=""
              />
              is for Tournaments. <span>Know more here.</span>
            </div>

            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window">

                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics%20Lite`}
                >
              <span className="blink text-danger d-block text-right">◉ LIVE</span>
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo_classic.png"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">Ludo Classic</div>
                  <picture className="gameCard-icon"><img src={closeicon} alt=""/></picture>
                </Link>
                <Link className="gameCard-container">
                <span className="blink text-danger d-block text-right">◉ LIVE</span>

                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo_popular.png"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">Snake</div>
                  <picture className="gameCard-icon"><img src={closeicon} alt=""/></picture>
                </Link>

                <Link className="gameCard-container">
                <span className="blink text-danger d-block text-right">◉ LIVE</span>

                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/ludo-SNK.png"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">Manual Room Card</div>
                  <picture className="gameCard-icon"><img src={closeicon} alt=""/></picture>
                </Link>

                <Link className="gameCard-container">
                <span className="blink text-danger d-block text-right">◉ LIVE</span>

                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/rummy.png"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">Aviator</div>
                  <picture className="gameCard-icon"><img src={closeicon} alt=""/></picture>
                </Link>
              </div>
            </div>
            <div className="accordion">
            <div className="accordion-item">
        <div
          className={`accordion-title ${activeIndex === 0 ? 'active' : ''}`}
          onClick={() => onItemClick(0)}
        >
          <img src={logo} width={50}/>
            
         <span className="accordianheader"> Terms, Privacy, Support</span>
        </div>
        <div
          className={`accordion-content ${activeIndex === 0 ? 'open' : ''}`}
        >
          <div
  className="px-3 overflow-hidden"
  style={{ height: 130, transition: "height 0.5s ease 0s" }}
>
  <div className="row footer-links">
    <Link className="col-6" to="/term-condition">
      Terms &amp; Condition
    </Link>
    <Link className="col-6" to="/privacy-policy">
      Privacy Policy
    </Link>
    <Link className="col-6" to="/refund-policy">
      Refund/Cancellation Policy
    </Link>
    <Link className="col-6" to="/contact-us">
      Contact Us
    </Link>
    <Link className="col-6" to="/responsible-gaming">
      Responsible Gaming
    </Link>
    <Link className="col-6" to="/platformcommision">
      Platform Commission
    </Link>
    {/* <Link className="col-6" to="/tds-policy">
      TDS Policy
    </Link>
    <Link className="col-6" to="/gst-policy">
      GST Policy
    </Link> */}
  </div>
</div>

        </div>
      </div>
    

    </div>

  <div className="footer-divider" />
  <div className="py-4">
    <div className="footer-text-bold">Our Business &amp; Products</div>
    <br />
    <div className="footer-text">
      We are an HTML5 game-publishing company and our mission is to make
      accessing games fast and easy by removing the friction of app-installs.
    </div>
    <br />
    <div className="footer-text">
      {webname} is a skill-based real-money gaming platform accessible only for
      our users in India. It is accessible on{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://winbro.in"
      >
        https://winbro.in
      </a>
      . On {webname}, users can compete for real cash in Tournaments and Battles.
      They can encash their winnings via popular options such as Paytm Wallet,
      Amazon Pay, Bank Transfer, Mobile Recharges etc.
    </div>
    <br />
    <div className="footer-text-bold">Our Games</div>
    <br />
    <div className="footer-text">
      {webname} has a wide-variety of high-quality, premium HTML5 games. Our games
      are especially compressed and optimised to work on low-end devices,
      uncommon browsers, and patchy internet speeds.
    </div>
    <br />
    <div className="footer-text">
      We have games across several popular categories: Arcade, Action,
      Adventure, Sports &amp; Racing, Strategy, Puzzle &amp; Logic. We also have
      a strong portfolio of multiplayer games such as Ludo, Chess, 8 Ball Pool,
      Carrom, Tic Tac Toe, Archery, Quiz, Chinese Checkers and more! Some of our
      popular titles are: Escape Run, Bubble Wipeout, Tower Twist, Cricket
      Gunda, Ludo With Friends. If you have any suggestions around new games
      that we should add or if you are a game developer yourself and want to
      work with us, don't hesitate to drop in a line at{" "}
      <a href="mailto:info@winbro.in">info@winbro.in</a>!
    </div>
  </div>


          </section>

          <div class="whatapp_link">
            <a href="https://api.whatsapp.com/send?phone=917689910036&amp;text=">
              <i class="fa fa-whatsapp"></i>
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
