import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/layout.css";
import "../css/login.css";
import loginss from "./ss.png";
import DeviceDetector from "device-detector-js";
import { Address4 } from "ip-address";
import Downloadbutton from "../Components/Downloadbutton";

export default function Login() {
  const history = useHistory();
  const [Phone, setPhone] = useState();
  // const [Password, setPassword] = useState();
  const [twofactor_code, settwofactor_code] = useState();
  const [otp, setOtp] = useState(false);
  const [secretCode, setSecretCode] = useState();
  const [ipAddress, setIpAddress] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [referral, setReferral] = useState(
    useLocation().pathname.split("/")[2]
  );

  useEffect(() => {
    // Get IP address
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ip = response.data.ip;
        const address = new Address4(ip);
        setIpAddress(address.address);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });

    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    setDeviceName(device.device.model || "Unknown Device");
  }, []);

  const handleClick = async (e) => {
    // debugger;
    e.preventDefault();

    if (!Phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone",
      });
    } else if (Phone.length != 10) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please  enter currect phone number",
      });
    } else {
      await axios
        .post(`https://qwxtive.winbro.in/user-login-send-tsats`, {
          Phone,
          referral,
          ipAddress,
          deviceName,
        })
        .then((respone) => {
          if (respone.data.status == 101) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: respone.data.msg,
            });
          } else if (respone.data.status == 200) {
            // Swal.fire({
            //   icon: "success",
            //   title: "your OTP",
            //   text: respone.data.myToken,
            // });
            setOtp(true);
            console.log(respone.data);
            setSecretCode(respone.data.secret);
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
            // width: '20%',
            // height:'20%',
          });
        });
    }
  };

  const varifyOtp = async (e) => {
    // debugger;
    e.preventDefault();
    console.log("verify otp sumbut req");
    if (!Phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
    } else {
      await axios
        .post(`https://qwxtive.winbro.in/login/finish`, {
          Phone,
          twofactor_code,
          referral,
          secretCode,
        })
        .then((respone) => {
          if (respone.data.status == 101) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: respone.data.msg,
            });
          } else if (respone.data.status == 200) {
            const token = respone.data.token;
            localStorage.setItem("token", token);
            window.location.reload(true);
            setTimeout(function () {
              history.push("/Games");
            }, 1000);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  };

  const setError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Invalid Number",
      confirmation: true,
    });
  };
  return (
    <>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <div className="main-area bg-dark">
          <div style={{ overflowY: "hidden" }}>
            <div className="splash-overlay" />
            <div className="splash-screen animate__bounce infinite ">
              <figure>
                <img width="100%" src={loginss} alt="" />
              </figure>
            </div>

            <div className="position-absolute center-xy login_center">
              <div
                style={{
                  color: "#fff",
                  padding: "8px 0",

                  width: "100%",
                }}
                className="d-flex center-xy  font-15 mb-4  "
              >
                Sign In and Sign Up
              </div>
              <div className="card w-100">
                <div className="card-body">
                  <div
                    className="bg-whtie  cxy flex-column"
                    style={{
                      width: "100%",
                      height: "auto",
                      backgroundColor: "#ffffff",
                      borderRadius: "5px",
                      border: "2px solid lightgray",
                    }}
                  >
                    <div
                      className="input-group bg-white"
                      style={{ transition: "top 0.5s ease 0s" }}
                    >
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text"
                          style={{
                            fontSize: "16px",
                            padding: "7px 5px 3px 20px",
                            border: "0px solid #d8d6de",
                            width: "100px",
                            background: "#e9ecef",
                          }}
                        >
                          +91
                        </div>
                      </div>
                      <input
                        className="form-control bg-white"
                        name="mobile"
                        type="tel"
                        placeholder="Mobile number"
                        onChange={(e) => {
                          setPhone(e.target.value);
                          if (e.target.value.length > 10) {
                            setError(true);
                          }
                        }}
                        style={{
                          transition: "all 3s ease-out 0s",
                          letterSpacing: "1px",
                          border: "none",
                          fontSize: "1px",
                        }}
                      />
                    </div>
                  </div>
                
                {otp && (
                  <div
                    className="bg-white  cxy flex-column"
                    style={{
                      width: "100%",
                      height: "auto",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      marginTop: "10px",
                      border: "2px solid #e5e6e6",
                    }}
                  >
                    <div
                      className="input-group"
                      style={{ transition: "top 0.5s ease 0s"}}
                    >
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text"
                          style={{
                            fontSize: "16px",
                            padding: "7px 5px 3px 20px",
                            border: "0px solid #d8d6de",
                            width: "100px",
                            background: "#e9ecef",
                          }}
                        >
                          OTP
                        </div>
                      </div>
                      <input
                        className="form-control"
                        name="password"
                        type="tel"
                        placeholder="Enter OTP"
                        onChange={(e) => settwofactor_code(e.target.value)}
                        style={{
                          transition: "all 3s ease-out 0s",
                          letterSpacing: "4px",
                          borderRadius: "4px",
                          border: "0px solid #d8d6de",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              </div>
              {!otp && (
                <button
                  className="Login-button cxy mt-4"
                  onClick={(e) => handleClick(e)}
                >
                  Continue
                </button>
              )}
              {otp && (
                <button
                  className="Login-button cxy mt-4"
                  onClick={(e) => varifyOtp(e)}
                >
                  Verify
                </button>
              )}

              <span
                style={{
                  color: "#fff",
                  fontWeight: "700",
                  marginBottom: "10px",
                }}
                className="mt-4 "
              >
                Don't have an account? <a href="/register"> Register</a>
              </span>
            </div>
            <div className="downloadButton">
              <Downloadbutton />
            </div>
            <div className="login-footer text-white">
              By continuing I agree that Fast battle Pvt. Ltd. may store and
              process my data in accordance with the{" "}
              <Link className=" text-white" to="/term-condition">
                Terms of Use
              </Link>
              ,
              <Link className=" text-white" to="/PrivacyPolicy">
                Privacy Policy
              </Link>{" "}
              and that I am 18 years or older. I am not playing from Assam,
              Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
