import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../css/layout.css";
import "../css/login.css";

export default function Footer() {
  return (
    <>
      <div
      >
        {/* <div className="footer_menu">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/"><i class="fa-solid fa-house"></i></Link>
            <Link to="/wallet"><i class="fa-solid fa-wallet"></i></Link>
            <Link to="/profile"><i class="fa-solid fa-user"></i></Link>
          </div>
        </div> */}
      </div>
    </>
  );
}
